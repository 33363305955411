import React from 'react';
import { Fade } from "react-awesome-reveal";
import diagram from '../assets/images/Archipelagos_Labs-Diagram.svg';
import diagram_mob from '../assets/images/Archipelagos_Labs-Diagram-Mob.svg';

function Transformation() {
    return (
        <div className="TransformationSection px-0 lg:px-12 xl:px-40">
            <div className="MainContainer max-w-7xl my-0 mx-auto">
                <div className="SectionContent px-8 lg:px-12 xl:px-16 py-16 lg:py-24">
                    <Fade delay={400} duration={1800} triggerOnce='true'>
                        <div className="Title pb-16">
                            <h2 className="text-al-h2 uppercase font-normal tracking-h2 text-al-blue">Transforming Challenges Into Opportunities</h2>
                        </div>
                    </Fade>
                    <Fade delay={800} duration={2400} triggerOnce='true'>
                        <div className="Content columns-1 gap-16 xl:columns-2">
                            <p className="text-al-p font-light tracking-p text-black">
                                We empower companies with real-time, data-driven decision making. By connecting ecosystems, our open platform harnesses the power of machine learning to transform vast volumes of structured or unstructured data, no matter the source, into actionable insights. Put simply, we liberate your data. These insights provide a comprehensive view of operations, customer behaviour, and system performance by individual device with every data point contributing to real time analysis at scale, enabling swift, strategic decisions.
                                <br></br>
                                <br></br>
                                With hardened security, and data that never leaves a client specified region, companies can confidently navigate their future and turn data into their second most strategic asset after their people.
                            </p>
                        </div>
                    </Fade>
                    <Fade delay={1200} duration={2400} triggerOnce='true'>
                        <div className="Diagram pt-20">
                            <img src={diagram} className="lg:block hidden" alt="diagram" />
                            <img src={diagram_mob} className="block lg:hidden" alt="diagram" />
                        </div>
                    </Fade>
                </div>
            </div>

        </div >

    );
}

export default Transformation;