import React from 'react';
import { Fade } from "react-awesome-reveal";
import icon1 from '../assets/images/Archipelagos_Labs-TransparentIcon.svg'
import icon2 from '../assets/images/Archipelagos_Labs-ActionIcon.svg'
import icon3 from '../assets/images/Archipelagos_Labs-CostIcon.svg'
import icon4 from '../assets/images/Archipelagos_Labs-EnvironmentalIcon.svg'

function Impact() {
    return (
        <div className="ImpactSection relative bg-al-blue px-0 lg:px-12 xl:px-40">
            <div className="MainContainer max-w-7xl my-0 mx-auto">
                <div className="SectionContent px-8 lg:px-12 xl:px-16 py-16 lg:py-24">
                    <Fade delay={400} duration={1800} triggerOnce='true'>
                        <div className="Title pb-16">
                            <h2 className='text-al-h2 uppercase font-normal tracking-h2 text-white'>Measurable Operational & Environmental Impact</h2>
                        </div>
                    </Fade>
                    <Fade delay={800} duration={2400} triggerOnce='true'>
                        <div className="Content flex flex-col">
                            <div className="ContentColumn w-full flex flex-col xl:flex-row columns-1 xl:gap-16 xl:columns-2">
                                <div className="ImpactSubSection flex-1 pb-12">
                                    <div className="ImpactSubHeader flex flex-row pb-2.5 border-b border-white">
                                        <div className="ImpactSubTitle flex flex-9">
                                            <h3 className="font-medium text-al-h3 uppercase text-white">Transparent Observability</h3>
                                        </div>
                                        <div className="ImpactSubIcon flex flex-row flex-1 justify-end">
                                            <img src={icon1} className="ImpactIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ImpactSubContent">
                                        <p className="text-al-p font-light tracking-p mt-3 text-white">
                                            Releasing insights from siloed, archived and incompatible islands of data into an integrated platform allows the big picture to be viewed through a single pane of glass.
                                        </p>
                                    </div>
                                </div>
                                <div className="ImpactSubSection flex-1 pb-12">
                                    <div className="ImpactSubHeader flex flex-row pb-2.5 border-b border-white">
                                        <div className="ImpactSubTitle flex flex-9">
                                            <h3 className="font-medium text-al-h3 uppercase text-white">Environmental Impact</h3>
                                        </div>
                                        <div className="ImpactSubIcon flex flex-row flex-1 justify-end">
                                            <img src={icon4} className="ImpactIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ImpactSubContent">
                                        <p className="text-al-p font-light tracking-p mt-3 text-white">
                                            Our innovation-driven approach prioritises environmental sustainability, reduces your ecological footprint, and provides unequivocal information that you can deliver to your customers and shareholders.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ContentColumn w-full flex flex-col xl:flex-row columns-1 xl:gap-16 xl:columns-2">
                                <div className="ImpactSubSection flex-1 pb-12 xl:pb-0">
                                    <div className="ImpactSubHeader flex flex-row pb-2.5 border-b border-white">
                                        <div className="ImpactSubTitle flex flex-9">
                                            <h3 className="font-medium text-al-h3 uppercase text-white">Transferring Data into Action</h3>
                                        </div>
                                        <div className="ImpactSubIcon flex flex-row flex-1 justify-end">
                                            <img src={icon2} className="ImpactIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ImpactSubContent">
                                        <p className="text-al-p font-light tracking-p mt-3 text-white">
                                            The process of transforming information can reveal significant insights, allowing for effective interventions. Data that spans decades can help detect and visualise changes over time and forecast future movements.
                                        </p>
                                    </div>
                                </div>
                                <div className="ImpactSubSection flex-1 pb-12">
                                    <div className="ImpactSubHeader flex flex-row pb-2.5 border-b border-white">
                                        <div className="ImpactSubTitle flex flex-9">
                                            <h3 className="font-medium text-al-h3 uppercase text-white">Cost Optimisation</h3>
                                        </div>
                                        <div className="ImpactSubIcon flex flex-row flex-1 justify-end">
                                            <img src={icon3} className="ImpactIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ImpactSubContent">
                                        <p className="text-al-p font-light tracking-p mt-3 text-white">
                                            Legacy operations can be enhanced and decades of practical knowledge shared across your ecosystem. Success hinges on field operations, therefore it is crucial to equip your team with the correct tools. But whilst technology facilitates this, it's your people and processes that will ultimately determine success.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>

        </div >

    );
}

export default Impact;