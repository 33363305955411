import React from 'react';
import logo from '../assets/images/Archipelagos_Labs-Logo_white.svg';

function Navigation() {
    return (
        <div className="Navbar flex flex-row bg-transparent h-20 max-w-7xl my-2 py-6 px-8 lg:px-12 xl:px-16">
            <div className="Logo flex flex-1 flex-wrap content-center">
                <img src={logo} className="Archipelagos-logo h-16 w-auto" alt="logo" />
            </div>
            <div className="Email flex flex-1 flex-wrap content-center justify-end">
                <a
                    className="Email-link body text-white hover:text-opacity-80 font-medium no-underline text-xs sm:text-base md:text-xl xl:text-2xl hidden lg:inline"
                    href="mailto:info@archipelagos-labs.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    info@archipelagos-labs.com
                </a>
            </div>
        </div >

    );
}

export default Navigation;