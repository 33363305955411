import React from 'react';
import { Fade } from "react-awesome-reveal";
import icon1 from '../assets/images/Archipelagos_Labs-SecurityIcon.svg'
import icon2 from '../assets/images/Archipelagos_Labs-ScalabilityIcon.svg'
import icon3 from '../assets/images/Archipelagos_Labs-AvailabilityIcon.svg'
import icon4 from '../assets/images/Archipelagos_Labs-PerformanceIcon.svg'
import icon5 from '../assets/images/Archipelagos_Labs-DevicesIcon.svg'
import icon6 from '../assets/images/Archipelagos_Labs-AccessibilityIcon.svg'

function Architecture() {
    return (
        <div className="ArchitectureSection relative bg-al-mid-blue px-0 lg:px-12 xl:px-40">
            <div className="MainContainer max-w-7xl my-0 mx-auto">
                <div className="SectionContent px-8 lg:px-12 xl:px-16 py-16 lg:py-24">
                    <Fade delay={400} duration={1800} triggerOnce='true'>
                        <div className="Title pb-16">
                            <h2 className='text-al-h2 uppercase font-normal tracking-h2 text-white'>Secure, Scalable & Open Architecture</h2>
                        </div>
                    </Fade>
                    <Fade delay={800} duration={2400} triggerOnce='true'>
                        <div className="Content flex flex-col">
                            <div className="ContentColumn w-full flex flex-col xl:flex-row columns-1 xl:gap-16 xl:columns-2">
                                <div className="ArchitectureSubSection flex-1 pb-12">
                                    <div className="ArchitectureSubHeader flex flex-row pb-2.5 border-b border-white">
                                        <div className="ArchitectureSubTitle flex flex-9">
                                            <h3 className="font-medium text-xl uppercase text-white">Information Security</h3>
                                        </div>
                                        <div className="ArchitectureSubIcon flex flex-row flex-1 justify-end">
                                            <img src={icon1} className="ArchitectureIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ArchitectureSubContent">
                                        <p className="text-al-p font-light tracking-p mt-3 text-white">
                                            The platform is lightweight, GDPR compliant and follows industry best practices and standards, ensuring data confidentiality and availability.
                                        </p>
                                    </div>
                                </div>
                                <div className="ArchitectureSubSection flex-1 pb-12">
                                    <div className="ArchitectureSubHeader flex flex-row pb-2.5 border-b border-white">
                                        <div className="ArchitectureSubTitle flex flex-9">
                                            <h3 className="font-medium text-xl uppercase  text-white">Performance</h3>
                                        </div>
                                        <div className="ArchitectureSubIcon flex flex-row flex-1 justify-end">
                                            <img src={icon4} className="ArchitectureIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ArchitectureSubContent">
                                        <p className="text-al-p font-light tracking-p mt-3 text-white">
                                            Infrastructure built for providing low latency, low packet loss, and high overall network quality, allowing for quick resource deployment to meet application needs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ContentColumn w-full flex flex-col xl:flex-row columns-1 xl:gap-16 xl:columns-2">
                                <div className="ArchitectureSubSection flex-1 pb-12">
                                    <div className="ArchitectureSubHeader flex flex-row pb-2.5 border-b border-white">
                                        <div className="ArchitectureSubTitle flex flex-9">
                                            <h3 className="font-medium text-xl uppercase  text-white">Platform Scalability</h3>
                                        </div>
                                        <div className="ArchitectureSubIcon flex flex-row flex-1 justify-end">
                                            <img src={icon2} className="ArchitectureIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ArchitectureSubContent">
                                        <p className="text-al-p font-light tracking-p mt-3 text-white">
                                            Scale resources up or down depending on business needs, promoting cost-effectiveness and responsiveness to user demands.
                                        </p>
                                    </div>
                                </div>
                                <div className="ArchitectureSubSection flex-1 pb-12">
                                    <div className="ArchitectureSubHeader flex flex-row pb-2.5 border-b border-white">
                                        <div className="ArchitectureSubTitle flex flex-9">
                                            <h3 className="font-medium text-xl uppercase  text-white">Existing Devices & Data</h3>
                                        </div>
                                        <div className="ArchitectureSubIcon flex flex-row flex-1 justify-end">
                                            <img src={icon5} className="ArchitectureIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ArchitectureSubContent">
                                        <p className="text-al-p font-light tracking-p mt-3 text-white">
                                            Leverage your existing devices and assets, merging space and ground without the need or expense of replacing them.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ContentColumn w-full flex flex-col xl:flex-row columns-1 xl:gap-16 xl:columns-2">
                                <div className="ArchitectureSubSection flex-1 pb-12 xl:pb-0">
                                    <div className="ArchitectureSubHeader flex flex-row pb-2.5 border-b border-white">
                                        <div className="ArchitectureSubTitle flex flex-9">
                                            <h3 className="font-medium text-xl uppercase  text-white">High Availabilty</h3>
                                        </div>
                                        <div className="ArchitectureSubIcon flex flex-row flex-1 justify-end">
                                            <img src={icon3} className="ArchitectureIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ArchitectureSubContent">
                                        <p className="text-al-p font-light tracking-p mt-3 text-white">
                                            Support for large numbers of devices with the ability to isolate and partition network connectivity and middleware to achieve high fault tolerance and availability.
                                        </p>
                                    </div>
                                </div>
                                <div className="ArchitectureSubSection flex-1">
                                    <div className="ArchitectureSubHeader flex flex-row pb-2.5 border-b border-white">
                                        <div className="ArchitectureSubTitle flex flex-9">
                                            <h3 className="font-medium text-xl uppercase  text-white">Data Accessibility</h3>
                                        </div>
                                        <div className="ArchitectureSubIcon flex flex-row flex-1 justify-end">
                                            <img src={icon6} className="ArchitectureIcon" alt="icon" />
                                        </div>
                                    </div>
                                    <div className="ArchitectureSubContent">
                                        <p className="text-al-p font-light tracking-p mt-3 text-white">
                                            Open data formats ensure that your data can be readily accessed on multiple devices and platforms when you need it.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>

            </div>

        </div >

    );
}

export default Architecture;